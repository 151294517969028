import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Container from '@objects/container'
import Stage from '@components/stage'

type Props = {
  pageContext: {
    page: { title: string }
    modules: Array<{
      contentfulfields: Array<{
        key: string
        link: string
        media: {
          file: {
            url: string
          }
        }
      }>
    }>
  }
}

function RootIndex({ pageContext }: Props) {
  const videos = pageContext.modules[0].contentfulfields
  const page = pageContext.page
  const aspectRatio = 9 / 16 // You may need to adjust this based on your specific video aspect ratio

  return (
    <>
      <Stage
        alignTop={true}
        type={'generic'}
        title={page.title}
        ariaLabel={page.title}
      />
      <Container ariaLabel="arialabel.gebaerdensprache">
        <div style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
          {videos.map((video, index) => {
            let youtubeId = video.link ? video.link.split('=')[1] : null
            return (
              <div key={index}>
                {video.link ? (
                  <div
                    style={{
                      position: 'relative',
                      paddingTop: `${aspectRatio * 100}%`,
                    }}
                  >
                    <iframe
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                      }}
                      src={`https://www.youtube.com/embed/${youtubeId}`}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <video
                      data-track-content
                      data-tracking-id={video.key}
                      style={{ width: '100%', height: 'auto' }}
                      controls
                      src={video.media.file.url}
                    />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </Container>
    </>
  )
}

RootIndex.propTypes = {
  pageContext: PropTypes.object,
}

export default RootIndex

export const pageQuery = graphql`
  query {
    allContentfulPage(
      filter: { id: { eq: "59791148-6366-54c4-9c40-83c69bdf08b5" } }
    ) {
      nodes {
        modules {
          key
          contentfulfields {
            ... on ContentfulStaticField {
              __typename
              key
              link
              media {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
